import React from 'react'
import { useSelector } from 'react-redux'
import AppMenuitem from './AppMenuitem'

function AppMenu() {
	const { user } = useSelector(state => state.user)

	const deployMenu = user.deploy_enabled_flag == 1 ? {
		label: 'Deploy',
		items: [
			{ label: 'Db CHA data', icon: 'pi pi-database', to: '/deploy/db/cha/data' },
			{ label: 'Releases', icon: 'pi pi-database', to: '/deploy/cha/releases' },
			{ label: 'Rilascio codice', icon: 'fas fa-code', to: '/deploy/code/scripts' },
		]
	} : {}

	const menu = [
		{
			label: 'Home',
			items: [{ label: 'Dashboard', icon: 'pi pi-home', to: '/' }]
		},
		{
			label: 'Cha',
			items: [
				{ label: 'Applicazioni', icon: 'pi pi-list', to: '/cha/applications' },

				{ label: 'Articoli di sistema', icon: 'pi pi-list', to: '/inv/system-items' },
				{ label: 'Aree e nodi', icon: 'pi pi-list', to: '/cha/areas' },
				{ label: 'Moduli', icon: 'pi pi-th-large', to: '/cha/modules' },
				{ label: 'Richieste documentali', icon: 'fas fa-file-contract', to: '/rms', items: [
					{ label: 'Categorie', icon: 'fa fa-layer-group', to: '/categories' },
				] },
				{ label: 'Driver e interfacce', icon: 'fa-solid fa-screwdriver-wrench', to: '/int', items: [
					{ label: 'Tipi di driver', icon: 'pi pi-list', to: '/types' },
					{ label: 'Interfacce', icon: 'fa-solid fa-network-wired', to: '/interfaces' },
					{ label: 'Drivers', icon: 'pi pi-wrench', to: '/drivers' },
				] },
				{ label: 'Trigger', icon: 'fa-solid fa-gun', to: '/cha', items: [
					{ label: 'Connettori', icon: 'fa-solid fa-wifi', to: '/trigger-connectors' },
					{ label: 'Azioni', icon: 'pi pi-wrench', to: '/trigger-actions' },
				] },
				{ label: 'Unità di misura', icon: 'pi pi-wrench', to: '/utl', items: [
					{ label: 'Gruppi', icon: 'fa fa-layer-group', to: '/um-groups' },
					{ label: 'Unità', icon: 'pi pi-wrench', to: '/um' },

				] },
				{ label: 'Modificatori di prezzo', icon: 'pi pi-briefcase', to: '/prc', items: [
					{ label: 'Tipi di modificatori', icon: 'pi pi-wrench', to: '/modifier-types' },
					{ label: 'Attributi', icon: 'pi pi-wrench', to: '/attributes' },

				] },
				{ label: 'Sezionali', icon: 'pi pi-list', to: '/cha/sectionals' },
				{ label: 'Avviso eventi', icon: 'fa fa-bell', to: '/todo/event-warning-types' },
				{ label: 'Aiuto in linea', icon: 'pi pi-question-circle', to: '/help/topics' },
				{ label: 'Dipendenti', icon: 'pi pi-user-edit', to: '/hr', items: [
					{ label: 'Causali', icon: 'fa fa-sheet-plastic', to: '/causals' },
					{ label: 'Tipi di spesa', icon: 'pi pi-dollar', to: '/expense-types' },

				] },
				{ label: 'Ecommerce', icon: 'pi pi-shopping-cart', to: '/ecom', items: [
					{ label: 'Informazioni', icon: 'pi pi-info-circle', to: '/informations/edit/1' },
					{ label: 'Metodi di pagamento', icon: 'pi pi-money-bill', to: '/payment-methods' },
					{ label: 'Metodi di spedizione', icon: 'pi pi-truck', to: '/shipping-methods' },
					{ label: 'Testi', icon: 'pi pi-align-center', to: '/texts' },
					{ label: 'Servizi', icon: 'pi pi-th-large', to: '/services' },
				] },
				{ label: 'Elearning', icon: 'fa-regular fa-lightbulb', to: '/eln', items: [
					{ label: 'Abbonamenti', icon: 'pi pi-check-square', to: '/subscriptions' },
					{ label: 'Enti certificatori', icon: 'fa-solid fa-stamp', to: '/certification-bodies' },
					{ label: 'Tipi di occupazione ECM', icon: 'fa-solid fa-certificate', to: '/ecm-employment-types' },
					{ label: 'Professioni ECM', icon: 'fa-regular fa-id-badge', to: '/ecm-professions' },
					{ label: 'Discipline ECM', icon: 'fa-solid fa-pen-clip', to: '/ecm-disciplines' },
					{ label: 'Tipologia di notifiche', icon: 'fa-regular fa-envelope', to: '/notification-types' },

				] },
				{ label: 'Liste valore', icon: 'pi pi-list', to: '/cha/lists' },
				{ label: 'Servizi di integrazione', icon: 'pi pi-cloud-download', to: '/si', items: [
					{ label: 'Servizi di integrazione', icon: 'pi pi-cloud-download', to: '/services' },
					{ label: 'Gruppi di servizi', icon: 'fa fa-layer-group', to: '/groups' },
				] },
				{ label: 'Riferimenti', icon: 'pi pi-wrench', to: '/cha', items: [
					{ label: 'Riferimenti', icon: 'pi pi-wrench', to: '/referements' },
					{ label: 'Traduzioni', icon: 'fas fa-language', to: '/referements-translations' },
				] },
				{ label: 'Tipi di immagine', icon: 'pi pi-images', to: '/fls/image-types' },
				{ label: 'Tipi di file', icon: 'pi pi-file', to: '/fls/file-types' },
				{ label: 'Testi', icon: 'pi pi-align-center', to: '/cha/texts' },
				//{ label: 'Traduzioni', icon: 'pi pi-language', to: '/cha/text-translations' },
				{ label: 'Casse previdenziali', icon: 'pi pi-dollar', to: '/act/social-security-taxes' },
				{ label: 'Elenco IVA', icon: 'pi pi-dollar', to: '/act/taxes' },
				{ label: 'Profili', icon: 'pi pi-user-edit', to: '/cha/profiles' },
				{ label: 'Ritenute', icon: 'pi pi-euro', to: '/act/with-holding-taxes' },
				{ label: 'Ruoli', icon: 'pi pi-user-plus', to: '/cha/roles' },
				{ label: 'Metodi di pagamento', icon: 'pi pi-money-bill', to: '/fnc/payment-methods' },
				{ label: 'Registrazione contatti', icon: 'pi pi-box', to: '/cha/registration-apps' },
				{ label: 'Territori', icon: 'pi pi-map', to: '/utl', items: [
					{ label: 'Paesi', icon: 'pi pi-map-marker', to: '/countries' },
					{ label: 'Zone', icon: 'pi pi-map-marker', to: '/country-zones' },
					{ label: 'Regioni', icon: 'pi pi-map-marker', to: '/regions' },
					{ label: 'Province', icon: 'pi pi-map-marker', to: '/provinces' },
					{ label: 'Città', icon: 'pi pi-map-marker', to: '/cities' },
					{ label: 'Aggiornamento', icon: 'fa-solid fa-cloud-arrow-down', to: '/territories-update' },
				] },
				{ label: 'Movimenti magazzino', icon: 'fa fa-warehouse', to: '/inv', items: [
					{ label: 'Causali di magazzino', icon: 'fa fa-sheet-plastic', to: '/transaction-causals' },
					{ label: 'Regole di magazzino', icon: 'fas fa-passport', to: '/documents-transaction-causals' },
				] },
				{ label: 'Documenti', icon: 'fa fa-paste', items: [
					{ label: 'Sezionali', icon: 'fa fa-file-invoice', to: '/cha/sectionals' },
					{ label: 'Documenti', icon: 'fas fa-file-contract', to: '/cha/documents' },
					{ label: 'Tipi di fattura di vendita', icon: 'fa fa-file-invoice', to: '/act/invoice-types' },
					{ label: 'Tipi di fattura di acquisto', icon: 'fa fa-file-invoice', to: '/act/payable-invoice-types' },
					{ label: 'Tipi di ordine di vendita', icon: 'fa fa-file-invoice', to: '/sal/order-types' },
					{ label: 'Tipi di ordine di acquisto', icon: 'fa fa-file-invoice', to: '/pur/order-types' },

				] },
				{ label: 'Modelli di comunicazioni', icon: 'fa fa-inbox', to: '/com/communications' },
				{ label: 'Modelli di messaggi', icon: 'fa fa-inbox', to: '/com/phone-messages' },
				{ label: 'Modelli di notifiche', icon: 'fa fa-bell', to: '/cha/notification-models' },
				{ label: 'Programmi concorrenti', icon: 'fa fa-rotate', to: '/cha/concurrents' },
				{ label: 'Report builder', icon: 'fa fa-print', to: '/rep/reports' },
				{ label: 'Report di stampa', icon: 'fa fa-print', to: '/cha', items: [
					{ label: 'Report', icon: 'fa fa-print', to: '/print-reports' },
					{ label: 'Temi', icon: 'fa-solid fa-palette', to: '/print-reports-themes' },
					{ label: 'Formati di stampa', icon: 'fa-regular fa-file', to: '/print-report-formats' },

				] },
			]
		},
		{
			label: 'Server',
			items: [
				{ label: 'Ambienti', icon: 'pi pi-server', to: '/server/cha/envs' },
				{ label: 'Logs', icon: 'pi pi-chart-bar', to: '/server/logs/view' },
				{ label: 'Negozi', icon: 'pi pi-shopping-cart', to: '/server/ecom/shops' },
				{ label: 'Portali BM', icon: 'pi pi-briefcase', to: '/server/bm/portals' },
			]
		},
		{
			label: 'Ambienti',
			items: [
				{ label: 'Moduli', icon: 'pi pi-th-large', to: '/environment/cha/modules' },
				{ label: 'Servizi d\'integrazione', icon: 'pi pi-cloud-download', to: '/environment/si/services' },
				{ label: 'Portali BM', icon: 'pi pi-briefcase', to: '/environment/bm/portals' },
				{ label: 'Tipi di immagine', icon: 'pi pi-images', to: '/environment/fls/image-types' },
				{ label: 'Tipi di file', icon: 'pi pi-file', to: '/environment/fls/file-types' },
				{ label: 'Ecommerce/Elearning', icon: 'pi pi-shopping-cart', to: '/environment/ecom', items: [
					{ label: 'Negozi', icon: 'pi pi-shopping-cart', to: '/shops' },
					{ label: 'Metodi di pagamento', icon: 'pi pi-money-bill', to: '/payment-methods' },
					{ label: 'Metodi di spedizione', icon: 'pi pi-truck', to: '/shipping-methods' },
					{ label: 'Abbonamenti', icon: 'pi pi-check-square', to: '/subscriptions', force_base: '/environment/eln' },
				] },
				{ label: 'Report di stampa', icon: 'fa fa-print', to: '/environment/cha', items: [
					{ label: 'Formati di stampa', icon: 'fa-regular fa-file', to: '/print-reports-formats' },
					{ label: 'Report', icon: 'fa fa-print', to: '/print-reports' },
					{ label: 'Temi', icon: 'fa-solid fa-palette', to: '/print-reports-themes' },
				] },
				{ label: 'Registrazione contatti', icon: 'pi pi-box', to: '/environment/cha/registration-apps' },
				{ label: 'Piani', icon: 'pi pi-shopping-cart', to: '/environment/cha/plans' },
				{ label: 'Email account di ambiente', icon: 'fa fa-envelope', to: '/environment/cha/mail-accounts' },
				{ label: 'Modelli di comunicazioni', icon: 'fa fa-inbox', to: '/environment/com/communications' },
				{ label: 'Modelli di notifiche', icon: 'fa fa-bell', to: '/environment/cha/notification-models' },
				{ label: 'Programmi concorrenti', icon: 'fa fa-rotate', to: '/environment/cha/concurrents' },
				{ label: 'Blog', icon: 'fa fa-newspaper', to: '/environment/ecom/blogs' },
				{ label: 'Report builder', icon: 'fa fa-print', to: '/environment/rep/reports' },
				{ label: 'Organizzazioni', icon: 'fa fa-building', to: '/environment/cha/organizations' },
			]
		},
		deployMenu,
		{
			label: 'Sviluppo',
			items: [
				{ label: 'Generazione codice', icon: 'fa-solid fa-screwdriver-wrench', to: '/develop/scripts' },
			]
		},
	]

	// Recursively order the menu items alphabetically
	const orderMenu = (items) => {
		items.sort((a, b) => {
			// Home, Cha, Server, Ambienti, Territori are not sorted
			if (['Home', 'Cha', 'Server', 'Ambienti'].includes(a.label)) return 1
			if (a.label < b.label) return -1
			if (a.label > b.label) return 1
			return 0
		})
		items.forEach(item => {
			// List of sub-items that are not sorted
			if (['Territori', 'Servizi di integrazione'].includes(item.label)) return
			if (item.items) {
				orderMenu(item.items)
			}
		})
	}

	orderMenu(menu)
	return (
		<ul className="layout-menu">
			{menu.map((item, i) => {
				return !item.separator ? <AppMenuitem item={item} root={true} index={i} key={i} /> : <li className="menu-separator"></li>
			})}
		</ul>
	)
}

export default AppMenu
