
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMessage, startLoad, stopLoad } from 'reducers/ui/uiSlice'
import { login, logout, setUser } from 'reducers/user/userSlice'
import { ConfirmDialog } from 'primereact/confirmdialog'

import http from 'axios'
import FeedBack from './FeedBack'

function Start({ children }) {
	const dispatch = useDispatch()
	const [isStarted, setStarted] = useState(false)

	useEffect(() => {
		const addInterceptors = () => {
			http.interceptors.request.use(
				(request) => {
					dispatch(startLoad())
					return request
				}
			)

			http.interceptors.response.use(
				(response) => {
					dispatch(stopLoad())
					return response.data
				},
				(error) => {
					dispatch(stopLoad())

					let message
					if (error.response == undefined) {
						message = 'Network error'
					} else {
						switch (error.response.status) {
							case 404:
								message = 'Service not found'
								break
							case 500:
								message = error.response.data.message || 'Internal server error'
								break

							default:
								if (typeof error.response.data.message == 'undefined') {
									message = error.response.data
								} else {
									message = error.response.data.message
								}
								break
						}
					}
					dispatch(setMessage({ message: { severity: 'error', summary: 'Errore', detail: message } }))

					return Promise.reject(error)
				}
			)
		}

		const checkUser = async() => {
			// verifico se l'utente ha già effettuato il login e lo carico dal token in localStorage
			const token = localStorage.getItem('token')
			if (token != 'undefined' && token) {
				dispatch(login(token))
				await http.get(`${window.__endpoint}/oauth/user/load`)
					.then(user => {
						dispatch(setUser(user))
					})
					.catch(error => {
						dispatch(logout())
					})
			}
			setStarted(true)
		}

		addInterceptors()
		checkUser()
	}, [])

	if (isStarted) {
		return <React.Fragment>
			<ConfirmDialog />
			<FeedBack />
			{ children }
		</React.Fragment>
	}
}

export default Start
