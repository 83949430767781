import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'

import Db from '@deploy/db/Db'
import Code from '@deploy/code/app'
import Cha from './cha/app'

function Deploy() {
	const { user } = useSelector(state => state.user)

	if (user.deploy_enabled_flag != 1) {
		// Se l'utente non è abilitato al deploy ritorno null
		return null
	}

	return <div>
		<Routes>
			<Route path="/db/*" element={<Db />} />
			<Route path="/cha/*" element={<Cha />} />
			<Route path="/code/*" element={<Code />} />
		</Routes>
	</div>
}

export default Deploy
