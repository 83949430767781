import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox, Select, InputTextarea, SelectMulti, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { TabView, TabPanel } from 'primereact/tabview'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [selectedOrganization, setSelectedOrganization] = useState(null)

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const organizations = await http.get(`${window.__endpoint}/environment/cha/organizations/list?cols=organization_id,name&order=name`)
		const shop_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${189}`)
		const provinces = await http.get(`${window.__endpoint}/utl/provinces/list?cols=province_id,name&order=name`)

		setSelectOptions({
			organizations: organizations.data,
			shop_types: shop_types.data,
			provinces: provinces.data,
		})

		if (editItem.organization_id) {
			listOrganizationLocations(editItem.organization_id)
		}
	}

	const listOrganizationLocations = async(organization_id) => {
		if (!organization_id) return
		const locations = await http.get(`${window.__endpoint}/environment/cha/organizations/listOrganizationLocations?cols=location_id,name,description&order=name&organization_id=${organization_id}`)
		setSelectOptions({
			...selectOptions,
			locations: locations.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['shop_name', 'web_url']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<TabView>
					<TabPanel header="Dati amministratore">
						<Row>
							{/* Organizzazione */}
							<Col all={6}>
								<FormGroup
									name="organization_id"
									label="Organizzazione"
									validationState={validator.validationState.organization_id}
								>
									<Select
										value={editItem.organization_id || ''}
										onChange={(e) => {
											onChange(e)
											setSelectedOrganization(e.value)
											listOrganizationLocations(e.value)
										} }
										options={selectOptions.organizations || []}
										optionValue={(o) => o.organization_id}
										optionLabel={(o) => o.name}
										all={{
											label: ' - Seleziona - '
										}}
									/>
								</FormGroup>
							</Col>
							{/* Prefisso url */}
							<Col all={6}>
								<FormGroup
									name="prefix_url"
									label="Prefisso url (solo per e-commerce wild card)"
									validationState={validator.validationState.prefix_url}
								>
									<InputText
										value={editItem.prefix_url || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Url e-commerce */}
							<Col all={6}>
								<FormGroup
									name="web_url"
									label="Url e-commerce (senza http)"
									validationState={validator.validationState.web_url}
								>
									<InputText
										value={editItem.web_url || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Url pwa */}
							<Col all={6}>
								<FormGroup
									name="pwa_url"
									label="Url pwa (senza http)"
									validationState={validator.validationState.pwa_url}
								>
									<InputText
										value={editItem.pwa_url || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Cartella del tema */}
							<Col all={6}>
								<FormGroup
									name="shop_theme"
									label="Cartella del tema (vuoto per default)"
									validationState={validator.validationState.shop_theme}
								>
									<InputText
										value={editItem.shop_theme || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Tipologia di negozio */}
							<Col all={6}>
								<FormGroup
									name="ecom_type_id"
									label="Tipologia di negozio"
									validationState={validator.validationState.ecom_type_id}
								>
									<Select
										value={editItem.ecom_type_id || ''}
										onChange={onChange}
										options={selectOptions.shop_types || []}
										optionValue={(o) => o.list_value_id}
										optionLabel={(o) => o.name}
										all={{
											label: ' - Seleziona - '
										}}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Stile personalizzato */}
							<Col all={12}>
								<FormGroup
									name="custom_css"
									label="Stile personalizzato"
									validationState={validator.validationState.custom_css}
								>
									<InputTextarea
										row={4}
										value={editItem.custom_css || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Ecommerce multi fornitore o */}
							<Col all={12}>
								<FormGroup
									name="display_multi_supplier"
									label="Ecommerce multi fornitore (mostra i prezzi di più fornitori per lo stesso articolo)"
									validationState={validator.validationState.display_multi_supplier}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.display_multi_supplier == 1}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Province dove si effettua la spedizione (lasciare vuoto per tutte) */}
							{selectOptions.provinces && <Col all={12}>
								<FormGroup
									name="shipping_provinces"
									label="Province dove si effettua la spedizione (lasciare vuoto per tutte)"
									validationState={validator.validationState.shipping_provinces}
								>
									<SelectMulti
										value={editItem.shipping_provinces || ''}
										onChange={onChange}
										options={selectOptions.provinces || []}
										optionValue={(o) => o.province_id}
										optionLabel={(o) => o.name}
										placeholder={'Seleziona la provincia'}
										all={{
											label: ' - Seleziona - '
										}}
									/>
								</FormGroup>
							</Col>}
						</Row>
					</TabPanel>
					<TabPanel header="Dati modalità">
						<Row>
							{/* Ecommerce multi fornitore o */}
							<Col all={6}>
								<FormGroup
									name="shop_mode_enabled"
									label="Modalità negozio attiva"
									validationState={validator.validationState.shop_mode_enabled}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.shop_mode_enabled == 1}
									/>
								</FormGroup>
							</Col>
							{/* Ecommerce multi fornitore o */}
							<Col all={6}>
								<FormGroup
									name="reservation_mode_enabled"
									label="Modalità prenotazione attiva"
									validationState={validator.validationState.reservation_mode_enabled}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.reservation_mode_enabled == 1}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Cartella del tema */}
							<Col all={6}>
								<FormGroup
									name="installments_increase_percent"
									label="Percentuale ricarico per acquisto a rate (Solo elearning)"
									validationState={validator.validationState.installments_increase_percent}
								>
									<InputText
										value={editItem.installments_increase_percent || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* ID del servizio che viene associato alle prenotazioni */}
							<Col all={6}>
								<FormGroup
									name="reservation_default_item_id"
									label="ID del servizio che viene associato alle prenotazioni (Solo ecommerce)"
									validationState={validator.validationState.reservation_default_item_id}
								>
									<InputText
										value={editItem.reservation_default_item_id || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel header="Avanzate">
						<Row>
							<Col all={12}>
								<h5>Elearning</h5>
							</Col>
						</Row>
						<Row>
							<Col all={12}>
								<p>
									Può essere controllata la quantità presente nel magazzino con quella che si sta acquistando. Se il primo flag è abilitato, viene mostrata la quantità disponibile<br/>
									Nel caso si abiliti il secondo flag, nel carrello verrà fatto il controllo tra la quantità selezionata e quella effettivamente disponibile. <br />
									<b>lasciare i campi deselezionati se non si vuole eseguire alcun controllo, ne mostrare la quantità</b>.
								</p>
							</Col>
						</Row>
						<Row>
							{/* Mostra la quantità disponibile */}
							<Col all={6}>
								<FormGroup
									name="quantity_available_display_flag"
									label="Mostra la quantità disponibile"
									validationState={validator.validationState.quantity_available_display_flag}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.quantity_available_display_flag == 1}
									/>
								</FormGroup>
							</Col>
							{/* Esegui il controllo sulla quantità disponibile */}
							<Col all={6}>
								<FormGroup
									name="quantity_available_check_flag"
									label="Esegui il controllo sulla quantità disponibile"
									validationState={validator.validationState.quantity_available_check_flag}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.quantity_available_check_flag == 1}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col all={12}>
								<br />
								<h5>Elearning</h5>
							</Col>
						</Row>
						<Row>
							{/* Durata del periodo d prova (giorni) */}
							<Col all={4}>
								<FormGroup
									name="offer_subscription_duration"
									label="Durata del periodo d prova (giorni)"
									validationState={validator.validationState.offer_subscription_duration}
								>
									<InputText
										type="number"
										value={editItem.offer_subscription_duration || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Chiave pubblica API zoom */}
							<Col all={4}>
								<FormGroup
									name="zoom_api_key"
									label="Chiave pubblica API zoom"
									validationState={validator.validationState.zoom_api_key}
								>
									<InputText
										type="number"
										value={editItem.zoom_api_key || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Chiave segreta API Zoom */}
							<Col all={4}>
								<FormGroup
									name="zoom_api_secret"
									label="Chiave segreta API Zoom"
									validationState={validator.validationState.zoom_api_secret}
								>
									<InputText
										type="number"
										value={editItem.zoom_api_secret || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel header="Dati gestibili anche dall'utente">
						<Row>
							{/* Nome del negozio */}
							<Col all={6}>
								<FormGroup
									name="shop_name"
									label="Nome del negozio"
									validationState={validator.validationState.shop_name}
								>
									<InputText
										value={editItem.shop_name || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Email */}
							<Col all={6}>
								<FormGroup
									name="shop_email"
									label="Email"
									validationState={validator.validationState.shop_email}
								>
									<InputText
										value={editItem.shop_email || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Telefono */}
							<Col all={6}>
								<FormGroup
									name="shop_phone"
									label="Telefono"
									validationState={validator.validationState.shop_phone}
								>
									<InputText
										value={editItem.shop_phone || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* indirizzo */}
							<Col all={6}>
								{!selectedOrganization > 0 ?
									<FormGroup
										name="location_id"
										label="Indirizzo"
										validationState={validator.validationState.location_id}
									>
										<InputText
											value={'Deve essere selezionata un\'organizzazione'}
											onChange={onChange}
											disabled
										/>
									</FormGroup>
									:
									<FormGroup
										name="location_id"
										label="Indirizzo"
										validationState={validator.validationState.location_id}
									>
										<Select
											value={editItem.location_id || ''}
											onChange={onChange}
											options={selectOptions.locations || []}
											optionValue={(o) => o.location_id}
											optionLabel={(o) => `${o.name} - ${o.description}`}
											all={{
												label: ' - Seleziona - '
											}}
										/>
									</FormGroup>
								}
							</Col>
						</Row>
						<Row>
							{/* Messaggio di benvenuto */}
							<Col all={12}>
								<FormGroup
									name="home_text"
									label="Messaggio di benvenuto"
									validationState={validator.validationState.home_text}
								>
									<HTMLEditor
										value={editItem.home_text || ''}
										rows={4}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Breve descrizione del negozio */}
							<Col all={12}>
								<FormGroup
									name="intro_text"
									label="Breve descrizione del negozio"
									validationState={validator.validationState.intro_text}
								>
									<HTMLEditor
										value={editItem.intro_text || ''}
										rows={4}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							{/* Descrizione negozio */}
							<Col all={12}>
								<FormGroup
									name="shop_description"
									label="Descrizione negozio"
									validationState={validator.validationState.shop_description}
								>
									<HTMLEditor
										value={editItem.shop_description || ''}
										rows={4}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel header="Icone social">
						<Row>
							{/* Facebook */}
							<Col all={6}>
								<FormGroup
									name="social_fb"
									label="Facebook"
									validationState={validator.validationState.social_fb}
								>
									<InputText
										value={editItem.social_fb || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Instagram */}
							<Col all={6}>
								<FormGroup
									name="social_ig"
									label="Instagram"
									validationState={validator.validationState.social_ig}
								>
									<InputText
										value={editItem.social_ig || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* LinkedIn */}
							<Col all={6}>
								<FormGroup
									name="social_ln"
									label="LinkedIn"
									validationState={validator.validationState.social_ln}
								>
									<InputText
										value={editItem.social_ln || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Twitter */}
							<Col all={6}>
								<FormGroup
									name="social_tw"
									label="X (Twitter)"
									validationState={validator.validationState.social_tw}
								>
									<InputText
										value={editItem.social_tw || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Numero Whatsapp */}
							<Col all={6}>
								<FormGroup
									name="social_wa"
									label="Numero WhatsApp"
									validationState={validator.validationState.social_wa}
								>
									<InputText
										value={editItem.social_wa || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPanel>
					<TabPanel header="Integrazioni">
						<Row>
							{/* ID Google Tag */}
							<Col all={6}>
								<FormGroup
									name="track_google_tag_id"
									label="ID Google Tag"
									validationState={validator.validationState.track_google_tag_id}
								>
									<InputText
										value={editItem.track_google_tag_id || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* ID Google Analytics */}
							<Col all={6}>
								<FormGroup
									name="track_google_analitycs_id"
									label="ID Google Analytics"
									validationState={validator.validationState.track_google_analitycs_id}
								>
									<InputText
										value={editItem.track_google_analitycs_id || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* ID Recensioni Google */}
							<Col all={6}>
								<FormGroup
									name="reviews_google_place_id"
									label="ID Recensioni Google"
									validationState={validator.validationState.reviews_google_place_id}
								>
									<InputText
										value={editItem.reviews_google_place_id || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* ID Facebook pixel */}
							<Col all={6}>
								<FormGroup
									name="track_facebook_pixel_id"
									label="ID Facebook pixel"
									validationState={validator.validationState.track_facebook_pixel_id}
								>
									<InputText
										value={editItem.track_facebook_pixel_id || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col all={12}>
								<FormGroup
									name="ecom_review_enabled_flag"
									label="Abilita recensioni negozio (Feedaty)"
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.ecom_review_enabled_flag == 1}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col all={12}>
								<h5>Integrazione Open Graph (elearning)</h5>
							</Col>
						</Row>
						<Row>
							{/* Titolo open graph */}
							<Col all={6}>
								<FormGroup
									name="og_title"
									label="Titolo open graph"
									validationState={validator.validationState.og_title}
								>
									<InputText
										value={editItem.og_title || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Nome sito open graph */}
							<Col all={6}>
								<FormGroup
									name="og_site_name"
									label="Nome sito open graph"
									validationState={validator.validationState.og_site_name}
								>
									<InputText
										value={editItem.og_site_name || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
							{/* Descrizione open graph */}
							<Col all={12}>
								<FormGroup
									name="og_description"
									label="Descrizione open graph"
									validationState={validator.validationState.og_description}
								>
									<HTMLEditor
										value={editItem.og_description || ''}
										rows={4}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col all={12}>
								<h5>Integrazione Open Graph (elearning)</h5>
							</Col>
						</Row>
						<Row>
							{/* Visualizza menu Metodi di pagamento */}
							<Col all={6}>
								<FormGroup
									name="payments_menu"
									label="Visualizza menu Metodi di pagamento"
									validationState={validator.validationState.payments_menu}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.payments_menu == 1}
									/>
								</FormGroup>
							</Col>
							{/* Visualizza menu Consegna della merce */}
							<Col all={6}>
								<FormGroup
									name="shipping_menu"
									label="Visualizza menu Consegna della merce"
									validationState={validator.validationState.shipping_menu}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.shipping_menu == 1}
									/>
								</FormGroup>
							</Col>
							{/* Visualizza menu Resi e rimborsi */}
							<Col all={6}>
								<FormGroup
									name="returns_menu"
									label="Visualizza menu Resi e rimborsi"
									validationState={validator.validationState.returns_menu}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.returns_menu == 1}
									/>
								</FormGroup>
							</Col>
							{/* Visualizza menu Allergeni */}
							<Col all={6}>
								<FormGroup
									name="restaurant_allergens_menu"
									label="Visualizza menu Allergeni"
									validationState={validator.validationState.restaurant_allergens_menu}
								>
									<Checkbox
										onChange={onChange}
										checked={editItem.restaurant_allergens_menu == 1}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPanel>
				</TabView>
			</form>}}
	</FormValidator>
}
export default Edit
